// 跳转类型 0无 1活动 2平台消息 3返水 4VIP 5分享赚钱 6外部链接
export function userJumpType() {
  const router = useRouter()
  function jump(item?: { jumpType: number; jumpContent: any }) {
    if (!item) return
    switch (item?.jumpType) {
      case 0:
        break
      case 1:
        router.push(`/discounts?type=1`)
        break
      case 2:
        router.push(`/noticeCenter`)
        break
      case 3:
        router.push(`/discounts?type=2`)
        break
      case 4:
        router.push(`/vip`)
        break
      case 5:
        router.push(`/share`)
        break
      case 6:
        if (inApp) {
          location.href = `km88://goexternal?urlencode=${encodeURIComponent(item.jumpContent)}`
        } else {
          window.location.href = item.jumpContent
        }
        break
    }
  }
  return {
    jump
  }
}
