import type { PageRequest, PageResponse, Response } from '~/type'
import type {
  CreditConfig,
  CreditHistory,
  CreditRecord,
  CreditTableConfig,
  UserCreditInfo
} from '~/type/credit'

// 积分规则配置
export async function getCreditPageConfig() {
  const res = await http<
    Response<
      CreditConfig[],
      {
        illustrate: { desc: string }
      }
    >
  >('/app-api/activity/point/credit/pointGiftConfig', {
    method: 'POST'
  })
  res.data.forEach((item) => {
    item.creditPoint = Number(item.creditPoint)
  })
  res.data.sort((a, b) => a.creditPoint - b.creditPoint)
  return res
}

// 积分系统交易记录
export function getCreditRecord(
  body: PageRequest<{
    // 交易类型 1_积分领取 2_积分提取 3_积分失效 4_积分发放 5_积分扣减
    // transactionType: boolean
    // 业务类型 1_信用分奖励 2_积分奖励 3_积分失效 4_人工上分 5_人工下分
    recordType: number
    startDate: number
    endDate: number
  }>
) {
  return http<PageResponse<CreditRecord>>(
    '/app-api/account/memberTransaction/queryScore',
    {
      method: 'POST',
      body
    }
  ).then((res) => {
    const { $i18n } = useNuxtApp()
    res.data?.records.forEach((item) => {
      switch (item.status) {
        case 1:
          item.statusProps = {
            text: $i18n.t('trade_record_ing_text')
          }
          break
        case 2:
          item.statusProps = {
            text: $i18n.t('trade_record_completed_text'),
            color: getResource('font-success-prim')
          }
          break
        case 3:
          item.statusProps = {
            text: $i18n.t('credit_expired_text'),
            color: getResource('font-danger-prim')
          }
          break
      }
    })
    return res
  })
}

// 积分历史明细
export function getCreditHistory(
  body: PageRequest<{
    // 状态 4:已领取 5:过期
    awardStatus: number
    startDate: number
    endDate: number
  }>
) {
  return http<PageResponse<CreditHistory>>(
    '/app-api/activity/point/credit/dayAwardRecordPage',
    {
      method: 'POST',
      body
    }
  )
}

// 积分提现
export function collectCreditPoints() {
  return http<
    Response<{
      /*编号 */
      accNo: string
      /*折损率 */
      lossRate: number
      /*折损金额 */
      lossAmount: number
      /*提取金额 */
      amount: number
    }>
  >('/app-api/activity/point/credit/pointWithdrawal', {
    method: 'POST',
    delay: 600,
    showLoading: true
  })
}

// 用户积分信息
export async function getUserCreditInfo() {
  const res = await http<Response<UserCreditInfo>>(
    '/app-api/activity/point/credit/pointInfo',
    {
      method: 'POST'
    }
  )
  res.data.credit = Math.min(Number(res.data.credit), 100)
  res.data.lossRate = Number(res.data.lossRate)
  res.data.audit = Number(res.data.audit)
  res.data.myPoint = Number(res.data.myPoint)
  res.data.rankScope = Number(res.data.rankScope)
  return res
}

// 领取日积分
export function collectDayCredit() {
  return http<
    Response<{
      /*积分奖励 */
      awardPoint: number
      /*稽核倍数 */
      auditRate: number
      // @Schema(description = "领取状态， 0成功，1失败")
      receiveStatus: number
      // @Schema(description = "需要充值次数")
      payCount: number
      // @Schema(description = "需要充值金额")
      payAmount: number
      // @Schema(description = "需要打码金额")
      auditAmount: number
    }>
  >('/app-api/activity/point/credit/receiveDayPointAward', {
    method: 'POST'
  })
}

// 积分系统介绍文案
export function getCreditIntro() {
  return http<
    Response<
      {
        /*信息 */
        titleMsg: string
        /*图片链接 */
        imageUrl: string
      }[]
    >
  >('/app-api/activity/point/pointIntroduction')
}

export function getCreditTableConfig2() {
  return http<Response<CreditTableConfig[]>>(
    '/app-api/activity/point/creditAwardRule'
  )
}
